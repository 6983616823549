import { useEffect, useRef, useState } from 'react';
import authorize, {
  AuthorizePayload
} from '../../../../utils/Payments/FreedomPay/authorize';
import fetchFPCalculate from '../../../../utils/Payments/FreedomPay/fetchFPCalculate';
import getCalculateObject from '../../../../utils/API/getCalculateObject';
import { useCartV2 } from '../../../../hooks/useCartV2';
import { PricingOptions } from '../../../Cart/types';
import extractSessionKey from '../../../../utils/Payments/FreedomPay/extractSessionKey';
import { OrderType } from '../../../../types/order';
import validate3DS from '../../../../utils/Payments/FreedomPay/validate3DS';
import {
  GatewayResponse,
  PaymentGatewayType,
  PaymentMethodType
} from '../../types/GatewayResponse';
import constructValidatePayload from '../../../../utils/Payments/FreedomPay/constructValidatePayload';
import { GatewayError } from '../../GatewayError';
import { getErrorDialogText } from '../../../../utils/Payments/Stripe/errors';

interface CreditCardIframeProps {
  iframeHtml: string;
  siteId: string;
  storeId: string;
  menuId: number;
  orderId: string;
  nameOnCard: string;
  orderTimeStamp: string;
  transactionTotal: number;
  sessionTimeout: () => void;
  onError: (error: GatewayError) => void;
  onSuccess: (formResponse: GatewayResponse) => void;
}

const CreditCardIframe: React.FC<CreditCardIframeProps> = ({
  iframeHtml,
  siteId,
  storeId,
  menuId,
  orderId,
  nameOnCard,
  orderTimeStamp,
  transactionTotal,
  sessionTimeout,
  onError,
  onSuccess
}) => {
  const { items: cartItems, priceToDisplay } = useCartV2();
  const iframeContainerRef = useRef<HTMLDivElement>(null);
  const [validateResponse, setValidateResponse] = useState<boolean | null>(
    null
  );
  const [paymentKey, setPaymentKey] = useState<string | null>(null);
  const [hasProcessed, setHasProcessed] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      sessionTimeout();
    }, 1800000); // 30 minutes

    return () => {
      clearTimeout(timeoutId);
    };
  }, [iframeHtml, sessionTimeout]);

  useEffect(() => {
    if (iframeContainerRef.current) {
      const updatedIframeHtml = `
        <style>
          #hpc--card-frame {
            height: 450px !important;
          }
        </style>
        ${iframeHtml}
      `;
      iframeContainerRef.current.innerHTML = updatedIframeHtml;
    }

    const messageEventListener = async (e: MessageEvent) => {
      const message = e.data;
      const data = message.data;
      switch (message.type) {
        case 3: // event listener for getting payment key from iframe response
          console.log(`case ${message.type} is hitting: `, data);
          setPaymentKey(data.paymentKeys[0]);
          break;
        // 14 or 16 receive 3DS parameters for security validation
        case 14:
        case 16:
          console.log(message, '3DS 16 message');
          try {
            const response = await validate3DS(constructValidatePayload(data));
            setValidateResponse(response);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (err: any) {
            const errorResponse = getErrorDialogText('processing_error');
            onError(
              new GatewayError(
                err.errorCode,
                PaymentMethodType.Card,
                errorResponse.title,
                errorResponse.description,
                undefined
              )
            );
          }
          break;
        default:
          break;
      }
    };

    window.addEventListener('message', messageEventListener);

    return () => {
      window.removeEventListener('message', messageEventListener);
    };
  }, [onError, iframeHtml]);

  useEffect(() => {
    const processPayment = async () => {
      if (!hasProcessed && validateResponse === true && paymentKey) {
        console.log('Processing payment...');

        setHasProcessed(true);

        try {
          const calculateObject = getCalculateObject(
            cartItems,
            priceToDisplay === PricingOptions.TAKEOUT
              ? OrderType.takeOut
              : OrderType.dineIn,
            null,
            menuId
          );

          const calculateResponse = await fetchFPCalculate(
            storeId,
            calculateObject
          );

          const authorizePayload: AuthorizePayload = {
            PaymentKey: paymentKey,
            PosSyncAttemptNumber: 1,
            orderTimeStamp,
            nameOnCard,
            invoiceNumber: orderId.toString(),
            chargeAmount: calculateResponse.chargeAmount,
            taxTotal: calculateResponse.taxTotal,
            items: calculateResponse.items,
            bearerSessionKey: extractSessionKey(iframeHtml),
            channel: 'mobile'
          };

          const authorizeResponse = await authorize(siteId, authorizePayload);
          console.log('AUTHORIZE RESPONSE: ', authorizeResponse);

          if (authorizeResponse.decision === 'ACCEPT') {
            onSuccess({
              type: 'credit_card',
              amount: transactionTotal,
              confirmationId: authorizeResponse.requestID,
              gateway: PaymentGatewayType.freedompay,
              paymentMethod: PaymentMethodType.Card
            });
          } else {
            const errorResponse = getErrorDialogText('processing_error');
            onError(
              new GatewayError(
                'declined',
                PaymentMethodType.Card,
                errorResponse.title,
                errorResponse.description,
                undefined
              )
            );
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
          onError(
            new GatewayError(
              err.errorCode,
              PaymentMethodType.Card,
              null,
              null,
              undefined
            )
          );
        }
      }
    };

    processPayment();
  }, [
    cartItems,
    onError,
    hasProcessed,
    iframeHtml,
    menuId,
    nameOnCard,
    onSuccess,
    orderId,
    orderTimeStamp,
    paymentKey,
    priceToDisplay,
    siteId,
    storeId,
    transactionTotal,
    validateResponse
  ]);

  return <div data-testid="iframe-renderer" ref={iframeContainerRef} />;
};

export default CreditCardIframe;
