import { AxiosResponse } from 'axios';
import { MenuItem, MenuResponseItem } from '../../features/Menu/types';
import mapAllergenStrings from './mapAllergenStrings';
import mapAllAttributes from './mapAllAttributes';
import mapAllModGroups from './mapAllModGroups';
import { MenuOptions } from '../../features/Cart/types';
import { StoreDay } from '../../types/stores';

const processMenuResponse = (menuResponse: AxiosResponse) => {
  const menuId = menuResponse.data.menuId;
  const menuResponseItemGroups = menuResponse.data.menu;
  const menuType: MenuOptions = menuResponse.data.pricingOptions;
  const menuHours: StoreDay[] = menuResponse.data.menuHours;
  const menuCategories: string[] = [];
  const menuItems: MenuItem[] = [];

  menuResponseItemGroups.forEach(
    (menuResponseItemGroup: { name: string; items: MenuResponseItem[] }) => {
      if (menuResponseItemGroup.items.length <= 0) {
        return;
      }

      menuCategories.push(menuResponseItemGroup.name);

      menuResponseItemGroup.items.forEach(
        (menuResponseItem: MenuResponseItem) => {
          let itemIcons = mapAllergenStrings(menuResponseItem.allergens);
          itemIcons = itemIcons.concat(
            mapAllAttributes(menuResponseItem.attributes)
          );

          const dietaryOptions = mapAllergenStrings(
            menuResponseItem.dietaryOptions.filter(
              // remove when we have paleo and keto icons
              (string) =>
                string.toLowerCase() !== 'paleo' ||
                string.toLowerCase() !== 'keto'
            )
          );

          const processedMenuResponseItem: MenuItem = {
            productId: menuResponseItem.productId,
            category: menuResponseItemGroup.name,
            dineInPrice: Number(menuResponseItem.dineInPrice),
            takeOutPrice: Number(menuResponseItem.takeOutPrice),
            name: menuResponseItem.name,
            description: menuResponseItem.description,
            allergens: itemIcons,
            dietaryOptions: dietaryOptions,
            imageUrl: menuResponseItem.imageUrl,
            calories: Number(menuResponseItem.calories),
            attributes: menuResponseItem.attributes,
            modGroups: mapAllModGroups(menuResponseItem.modGroups),
            isSuspended: menuResponseItem.isSuspended
          };

          menuItems.push(processedMenuResponseItem);
        }
      );
    }
  );

  return { menuCategories, menuItems, menuId, menuType, menuHours };
};

export default processMenuResponse;
