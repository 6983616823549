import { useEffect, useMemo, useState } from 'react';
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import isEqual from 'lodash.isequal';
import { Box, styled } from '@mui/material';
import MenuItemModifiers from '../Components/MenuItemModifiers';
import MenuItemImage from '../Components/MenuItemImage';
import MenuItemDetails from '../Components/MenuItemDetails';
import MenuItemAlcohol from '../Components/MenuItemAlcohol';
import MenuItemQuantity from '../Components/MenuItemQuantity';
import ConfirmationDialog from '../../../components/ConfirmationDialog/ConfirmationDialog';
import MenuItemAddToOrder from '../Components/MenuItemAddToOrder';
import MenuItemSpecialInstructions from '../Components/MenuItemSpecialInstructions';
import Button, { ButtonProps } from '@mui/material/Button';
import { useCartV2 } from '../../../hooks/useCartV2';
import { useTimeDisplay } from '../../../hooks/useTimeDisplay';
import { useMenu } from '../../../hooks/useMenu';
import { useAnalytics } from '../../../hooks/useAnalytics';
import getFullPrice from '../../../utils/Menu/getFullPrice';
import setCartItemModifiers from '../../../utils/Cart/setCartItemModifiers';
import { StoreInterface } from '../../../types/stores';
import { MenuItem, ModItem } from '../../Menu/types';
import AppRoute from '../../../routing/AppRoute';
import { menuConfig } from '../../../config/appConfig';
import { Helmet } from 'react-helmet';
import OrderingUnavailableDialog from '../../../components/OrderingUnavailableDialog/OrderingUnavailableDialog';
import { PricingOptions } from '../../Cart/types';
import MenuItemFlag from '../../Menu/components/MenuItemFlag/MenuItemFlag';
import Banner from '../../../components/Banner/Banner';
import { formatInTimeZone } from 'date-fns-tz';
import { useSiteConfig } from '../../../hooks/useSiteConfig';
import { isStoreAboutToClose } from '../../../utils/Store/isStoreAboutToClose';
import { format } from 'date-fns';
import formatDisplayTime from '../../../utils/Menu/formatDisplayTime';

interface MenuItemPageProps {
  store: StoreInterface;
}

const MenuItemContainer = styled('div')(({ theme }) => ({
  paddingBottom: theme.spacing(20),
  display: 'flex',
  flexDirection: 'column'
}));

const StyledMenuItemFooter = styled('div')(({ theme }) => ({
  paddingBottom: theme.spacing(4),
  position: 'fixed',
  bottom: 0,
  width: '100%',
  margin: 'auto',
  maxWidth: '1024px',
  display: 'flex',
  justifyContent: 'space-between',
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  paddingTop: theme.spacing(4),
  borderTop: `1px solid ${theme.colors.gray[300]}`,
  backgroundColor: theme.colors.base.white,
  zIndex: 1
}));

const BannerHeaderContainer = styled(Box)(({ theme }) => ({
  marginBottom: 0,
  marginTop: theme.spacing(13)
}));

const StyledMenuItemAddToOrder = styled('div')({
  width: '60%'
});

const StyledConfirmationButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.colors.partner[500]
}));

const StyledMenuItemTitle = styled('h2')(({ theme }) => ({
  fontSize: theme.spacing(6),
  fontWeight: 600,
  marginTop: 0,
  marginBottom: 0,
  marginLeft: theme.spacing(3.84),
  lineHeight: 1
}));

const MenuItemPage = ({ store }: MenuItemPageProps) => {
  const { pathname } = useLocation();
  const { editItemIndex } = useParams();
  const {
    items: cartItems,
    updateItem,
    addItem,
    removeItem,
    priceToDisplay
  } = useCartV2();
  const { itemId } = useParams();
  const isEdit = pathname.includes('/edit');
  const itemToModify = cartItems[Number(editItemIndex)] ?? null;
  const { displayTimes, isFetched } = useTimeDisplay(store.id);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const logEvent = useAnalytics();
  const { partnerConfig: siteConfig } = useSiteConfig();
  const { data: menu } = useMenu(store?.id || '');

  const { data } = useMenu(store.id);
  const menuItems = useMemo(() => (data ? data.menuItems : []), [data]);
  const selectedMenuItem = menuItems.find(
    (item) => item.productId.toString() === itemId
  ) as unknown as MenuItem;
  const [selectedOptions, setSelectedOptions] = useState<{
    [index: string]: ModItem;
  }>(isEdit ? itemToModify?.modifiers : {});
  const [quantity, setQuantity] = useState<number>(
    isEdit ? itemToModify?.quantity : 1
  );
  const [itemNotes, setItemNotes] = useState<string>(
    isEdit ? itemToModify?.itemNotes : ''
  );

  const [selectionError, setSelectionError] = useState(false);
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openOrderingUnavailableDialog, setOpenOrderingUnavailableDialog] =
    useState<boolean>(store.isMobileActive === false);
  const [updated, setUpdated] = useState<boolean>(false);
  const [redirect, setRedirect] = useState<boolean>(false);
  const [bannerVisible, setBannerVisible] = useState(false);
  const [closingTimeToday, setClosingTimeToday] = useState('');

  const onInstructionChange = (val: string) => {
    setItemNotes(val);
    setUpdated(true);
  };

  const onQuantityChange = (val: number) => {
    setQuantity(val);
    setUpdated(true);
  };

  const addSelection = (val: ModItem) => {
    selectedOptions[val.name] = val;
    setSelectedOptions({ ...selectedOptions });
    setSelectionError(false);
    setUpdated(true);
  };

  const removeSelection = (val: ModItem) => {
    delete selectedOptions[val.name];
    setSelectedOptions({ ...selectedOptions });
    setUpdated(true);
  };

  const setNewCartItem = () => {
    const menuItemWithOptions = setCartItemModifiers(
      selectedMenuItem,
      selectedOptions
    );

    return {
      ...menuItemWithOptions,
      quantity: quantity,
      itemNotes: itemNotes
    };
  };

  const handleUpdateOrder = () => {
    const updatedCartItem = setNewCartItem();
    if (
      cartItems.find(
        (val) =>
          updatedCartItem.productId === val.productId &&
          isEqual(
            Object.keys(updatedCartItem.modifiers).sort(),
            Object.keys(val.modifiers).sort()
          )
      )
    ) {
      removeItem(updatedCartItem, Number(editItemIndex));
      addItem(updatedCartItem);
    } else {
      updateItem(updatedCartItem, Number(editItemIndex));
    }
    toast.success(t('restaurant.menu.updatedItemToCart'));
  };

  const hasGroupOptionSelected = (modName: string): boolean => {
    for (const key in selectedOptions) {
      if (!selectedOptions.hasOwnProperty(key)) {
        return false;
      }

      if (selectedOptions[key].modifierName === modName) {
        return true;
      }
    }

    return false;
  };

  const handleRequired = (): boolean => {
    if (
      !selectedMenuItem.modGroups ||
      selectedMenuItem.modGroups.length === 0
    ) {
      return false;
    }

    let showDialog = false;

    selectedMenuItem.modGroups.forEach((modifier) => {
      const modName = modifier.name;
      let required = false;

      if (modifier.style.toLowerCase() === 'radio') {
        required = true;
      }

      if (!required) {
        return;
      }

      if (hasGroupOptionSelected(modName)) {
        return;
      }

      showDialog = true;
    });

    if (!showDialog) {
      return false;
    }

    setOpenDialog(true);
    return true;
  };

  const handleAddToOrder = async () => {
    if (handleRequired()) {
      return;
    }

    setRedirect(true);

    if (isEdit) {
      return handleUpdateOrder();
    }

    const newCartItem = setNewCartItem();

    const analyticPayload = {
      item_qty: newCartItem.quantity,
      item_id: newCartItem.productId,
      category_name: newCartItem.category
    };

    // await is necessary for addItem to fire
    addItem(newCartItem);

    logEvent('add_to_cart', analyticPayload);

    if (newCartItem.itemNotes.length > 0) {
      logEvent('special_instructions', {
        item_id: newCartItem.productId,
        item_name: newCartItem.title,
        characters_used: newCartItem.itemNotes.length
      });
    }

    toast.success(t('restaurant.menu.addedToOrder'));
  };

  const handleCancel = () => {
    navigate(
      generatePath(AppRoute.CART, {
        storeId: String(store.shortId)
      })
    );
  };

  const getDineInVsTakeOutPrice = () => {
    return priceToDisplay === PricingOptions.TAKEOUT
      ? selectedMenuItem.takeOutPrice
      : selectedMenuItem.dineInPrice;
  };

  useEffect(() => {
    if (!redirect) {
      return;
    }

    if (isEdit) {
      navigate(
        generatePath(AppRoute.CART, {
          storeId: String(store.shortId)
        }),
        { replace: true }
      );

      return;
    }

    navigate(
      generatePath(AppRoute.MENU, {
        storeId: String(store.shortId)
      }),
      { replace: true }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItems, redirect, isEdit]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const getBannerStatus = (rightNow: Date) => {
      if (menu?.menuHours) {
        // Returns store end time in 24-hour format
        const dayOfWeek = format(rightNow, 'EEEE');
        const todaysStoreHours = menu.menuHours.filter(
          (menuDay) => menuDay[dayOfWeek]
        );
        const storeClosingTime = todaysStoreHours[0][dayOfWeek].endTime;
        setClosingTimeToday(storeClosingTime);

        // Returns user local time in store's timezone in 24-hour format
        const usersFormattedTime = formatInTimeZone(
          rightNow,
          siteConfig.timeZone,
          'HH:mm'
        );

        const status = isStoreAboutToClose(
          usersFormattedTime,
          storeClosingTime
        );
        setBannerVisible(status);
      }
    };

    const interval = setInterval(() => getBannerStatus(new Date()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  if (!isFetched) {
    return null;
  }
  if (!selectedMenuItem) {
    return null;
  }

  const disabled = !displayTimes.length;
  const helmetTitle = `${selectedMenuItem.name} ${t(
    'restaurant.menu.menuItem'
  )}`;
  const price = getDineInVsTakeOutPrice();

  if (!price) {
    return null;
  }

  return (
    <>
      <MenuItemContainer>
        <Helmet>
          <title>{helmetTitle}</title>
        </Helmet>
        <BannerHeaderContainer>
          <Banner
            bannerText={`${t('restaurant.menu.currentMenuEnds')} ${formatDisplayTime(closingTimeToday)}.`}
            hasRoundedCorners={false}
            isVisible={bannerVisible}
          />
        <MenuItemImage item={selectedMenuItem} screenWidth={screenWidth} />
        </BannerHeaderContainer>
        <MenuItemFlag
          flags={selectedMenuItem.attributes}
          style={{ lineHeight: '0', marginBottom: '12px' }}
        ></MenuItemFlag>
        <StyledMenuItemTitle data-testid="menu-item-title">
          {selectedMenuItem.name}
        </StyledMenuItemTitle>
        <MenuItemDetails
          item={selectedMenuItem}
          priceToDisplay={priceToDisplay}
        />
        <MenuItemModifiers
          item={selectedMenuItem}
          addSelection={addSelection}
          removeSelection={removeSelection}
          selectionError={selectionError}
          selectedOptions={selectedOptions}
        />
        {store.hasItemNotes && (
          <MenuItemSpecialInstructions
            instructions={itemNotes}
            title={selectedMenuItem.name}
            limit={menuConfig.specialInstructionsLimit}
            onChange={onInstructionChange}
          />
        )}
        <MenuItemAlcohol item={selectedMenuItem} />
        <StyledMenuItemFooter>
          <MenuItemQuantity
            quantity={quantity}
            disabled={
              !store.isMobileActive || disabled || selectedMenuItem.isSuspended
            }
            onChange={onQuantityChange}
          />
          <StyledMenuItemAddToOrder>
            <MenuItemAddToOrder
              price={getFullPrice(
                price,
                quantity,
                selectedOptions,
                priceToDisplay
              )}
              disabled={
                !store.isMobileActive ||
                disabled ||
                selectedMenuItem.isSuspended
              }
              isEdit={isEdit}
              isSuspended={selectedMenuItem.isSuspended}
              showCancel={!updated}
              onSubmit={handleAddToOrder}
              onCancel={handleCancel}
            />
          </StyledMenuItemAddToOrder>
        </StyledMenuItemFooter>
      </MenuItemContainer>
      <ConfirmationDialog
        open={openDialog}
        handleClose={() => setOpenDialog(!openDialog)}
        title={t('restaurant.menu.hungry')}
        content={t('restaurant.menu.selectModifier')}
        data-testid="confirmation-dialog"
      >
        <StyledConfirmationButton
          disableRipple
          onClick={() => setOpenDialog(!openDialog)}
          data-testid="dialog-cancel"
        >
          {t('restaurant.main.ok')}
        </StyledConfirmationButton>
      </ConfirmationDialog>
      <OrderingUnavailableDialog
        open={openOrderingUnavailableDialog}
        close={() =>
          setOpenOrderingUnavailableDialog(!openOrderingUnavailableDialog)
        }
      />
    </>
  );
};

export default MenuItemPage;
